var Turbolinks = require("turbolinks")

import './main.scss'

import './components/header/header.js'
import './components/home/home.js'
import './components/product/product.js'
import './components/promotion/promotion.js'
import './components/alcohol-gel/index.js'
import './components/blogs/index.js'

Turbolinks.start()
